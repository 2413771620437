.newsletter-module{
  .contact-form-email{
    width:70%;
    float:left;
    line-height: 77px;
    height:77px;
    border-top-left-radius:1000px;
    border-bottom-left-radius:1000px;
  }

  .btn{
    width:30% !important;
    float:left;
    height:77px;
    border-radius:1000px;
    margin-left:-30px !important;
    font-weight: bold;
  }
}