.wrapper-more{
  margin-top:30px;
}

.btn-gradient{
  color: #fff;
  #gradient .horizontal(@brand-color2,@brand-color3);
  padding: 15px 25px;
  text-transform:uppercase;
  border-radius:1000px;
  font-weight: 500;
  border:none;
  text-align:center;

  &:hover{
    #gradient .horizontal(@brand-color2,@brand-color3,30%);
    color: #fff;
  }
}




.btn-wrapper-align-center{
  text-align:center;
}

.module-btn{
  color: #fff;
  display: inline-block;
  padding: 15px 30px;
  text-align: center;
  margin: 15px 0;
  font-size: 18px;
  border-radius: 5px;
  &:hover{
    color: #fff;
    text-decoration: none;
  }
}

.module-btn-color-red{
  background: @brand-color;

  &:hover{
    background: darken(@brand-color, 5%);
    color: #fff;
    text-decoration: none;

  }
}


.module-btn-color-orange{
  background: orange;
  color: #fff;
  font-size: 16px;
  display: block;
  padding: 10px 10px;
  text-align: center;
  margin: 5px 0;

  &:hover{
    background: fade(orange, 95%);
    color: #fff;
    text-decoration: none;

  }
}

.module-btn-color-blue{
  background: #62a8ea;

  &:hover {
    background: fade(#62a8ea, 95%);
  }
}